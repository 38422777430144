import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import AOS from "aos";

import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Navigation from "../components/Navigation";

// import "aos/dist/aos.css";
import "../styles/tokens.css";
import "../styles/global.css";
import "../styles/section.css";

const Links = ({ data, location }) => {
  const { slug, shareImage, company, navigation } = data.contentfulPage;

  let lastBackgroundColor = null;

  const [scroll, setScroll] = useState(false);

  const seo = {
    noIndex: true,
    pageTitle: "Links - Expert Software DEV and Digital Marketing",
    pageDescription: "",
    shareImage,
    isBlogPost: false,
  };

  useEffect(() => {
    // AOS.init({ duration: 600 });
  }, []);

  const SocialLink = ({ social }) => {
    return (
      <a className="" href={social.link} target="_blank" rel="noreferrer">
        <Icon
          props={{
            size: "xl",
            isBrand: true,
            name: social.icon.name,
          }}
        />
      </a>
    );
  };

  return (
    <Layout
      styles={{ position: "relative" }}
      location={location}
      slug={slug}
      seo={seo}
      scroll={scroll}
      company={company}
    >
      <a href="#main" title="Skip to main content" className="sr-only">
        Skip to main content
      </a>
      <Navigation
        company={company}
        navigation={navigation}
        slug={slug}
        setScroll={setScroll}
        enableDarkNav={true}
      />
      <div id="main">
        <section className="section section">
          <div className="linktree">
            <ul>
              <li>
                <a href="https://themoonelloshow.com/" target="_blank">
                  Latest Podcast Episode
                </a>
              </li>
              <li>
                <Link to="../blog/">Latest Blog Post</Link>
              </li>
            </ul>
            <div className="img-container">
              <StaticImage
                src="../images/moonello-at-amity.jpg"
                alt="Moonello Brainstorming"
                placeholder="blurred"
                layout="fixed"
                width="600"
                height="200"
              />
            </div>
            <ul>
              <li>
                <Link to="../">Moonello.com</Link>
              </li>
              <li>
                <Link to="../contact/">Contact Us</Link>
              </li>
              <li>
                <Link to="../free-consultation/">Free Consultation</Link>
              </li>

              <li>
                <Link to="../resources/">Resources</Link>
              </li>
            </ul>

            <div className="sociallinks">
              {company.socialLinks.map((social) => {
                return <SocialLink social={social} key={social.name} />;
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "home" }) {
      name
      slug
      enableDarkNav
      noIndex
      pageTitle
      pageDescription
      shareImage {
        gatsbyImageData(width: 1500)
        description
        url
      }
      company {
        id
        name
        phone
        address {
          childMarkdownRemark {
            html
          }
        }
        email
        copyright
        socialLinks {
          platform
          icon {
            name
            isBrand
            size
          }
          link
        }
        footerNewsletter {
          id
          title
          type
          variant
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            alt
            file {
              gatsbyImageData(width: 1000)
              description
              url
              publicUrl
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          code
          buttonText
          redirectTo {
            slug
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }

      navigation {
        id
        name
        darkLogo {
          alt
          file {
            gatsbyImageData(width: 250)
            description
            url
            publicUrl
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        lightLogo {
          alt
          file {
            gatsbyImageData(width: 250)
            description
            url
            publicUrl
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        pages {
          navLabel
          slug
          pageTitle
          pageDescription
          shareImage {
            gatsbyImageData(width: 1000)
          }
          navSubPages {
            navLabel
            slug
          }
        }
        callToAction {
          navLabel
          slug
        }
      }
    }
  }
`;

export default Links;
